<template>
  <v-app>
    <v-row class="mt-10 mx-10" justify="space-between">
      <div>
        <p>
          <img :src="project.logo.preview" class="mt-3 mb-5" width="185" />
        </p>
        <span style="color: #f15858" class="font-weight-bold">{{ project.name }}</span>
        <div class="mt-7">
          <p class="font-weight-bold" style="color: black; margin-top: -10px">Invoice number</p>
          <p style="margin-top: -18px">
            {{ billing.invoice.id }}
          </p>
        </div>
        <div class="mt-5">
          <p class="font-weight-bold" style="color: black">Date paid</p>
          <p style="margin-top: -18px">
            {{ billing.invoice.paid_at ? formatDate(billing.invoice.paid_at) : formatDate(billing.createdAt) }}
          </p>
        </div>
        <v-btn
          v-if="billing.invoice.status.toLowerCase() === 'pending'"
          dense
          class="paid-badge"
          style="background-color: #fcbe2d; color: white"
          target="_blank"
          :href="billing.invoice.invoice_url"
        >
          {{ billing.invoice.status }}
        </v-btn>
        <p v-if="billing.invoice.status.toLowerCase() === 'paid'" class="paid-badge" style="background-color: #29cc6a">
          {{ billing.invoice.status }}
        </p>
        <p
          v-if="billing.invoice.status.toLowerCase() === 'expired'"
          class="paid-badge"
          style="background-color: #ff6666"
        >
          {{ billing.invoice.status }}
        </p>
      </div>
      <div class="mt-3">
        <p>From</p>
        <div>
          <p class="font-weight-bold mt-5" style="color: black; margin-top: -10px">
            {{ project.name ? project.name : '--' }}
          </p>
          <p style="margin-top: -18px; max-width: 250px" class="mt-3">
            {{ project.address ? project.address : '--' }}
          </p>
        </div>
        <div class="mt-5">
          <p>Phone number: {{ project.channel.phone_number ? project.channel.phone_number : '--' }}</p>
          <p style="margin-top: -18px">Email: {{ project.email ? project.email : '--' }}</p>
        </div>
      </div>
      <div class="mt-3">
        <p>To</p>
        <div>
          <p class="font-weight-bold mt-5" style="color: black; margin-top: -10px">
            {{ loanSub.loanSub.recipient.name ? loanSub.loanSub.recipient.name : '--' }}
          </p>
          <p style="margin-top: -18px; max-width: 250px" class="mt-3">
            {{ loanSub.loanSub.recipient.address ? loanSub.loanSub.recipient.address : '--' }}
          </p>
        </div>
        <div class="mt-5">
          <p>
            Phone number: {{ loanSub.loanSub.recipient.phone_number ? loanSub.loanSub.recipient.phone_number : '--' }}
          </p>
          <p style="margin-top: -18px">
            Email: {{ loanSub.loanSub.recipient.email ? loanSub.loanSub.recipient.email : '--' }}
          </p>
        </div>
      </div>
    </v-row>
    <div class="mt-10 mx-10 mb-10">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">QTY</th>
              <th class="text-left">Price</th>
              <th class="text-left">Subtotal</th>
            </tr>
          </thead>
          <tbody v-if="!loanSub.loanSub.transactionId">
            <tr>
              <td>{{ loanSub.loanTypeData.loanName ? loanSub.loanTypeData.loanName : '--' }}</td>
              <td>{{ loanSub.loanSub.totalLoan ? formatToIDRCurrency(loanSub.loanSub.totalLoan) : '--' }}</td>
              <td>{{ loanSub.loanSub.totalLoan ? formatToIDRCurrency(loanSub.loanSub.totalLoan) : '--' }}</td>
              <td>{{ loanSub.loanSub.totalLoan ? formatToIDRCurrency(loanSub.loanSub.totalLoan) : '--' }}</td>
            </tr>
          </tbody>
          <tbody v-if="loanSub.loanSub.transactionId">
            <tr>
              <td>{{ transaction.propertyName }}</td>
              <td>{{ '1' }}</td>
              <td>{{ transaction.price }}</td>
              <td>{{ transaction.price }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row justify="space-around" class="mt-10">
        <v-col>
          <p><span class="font-weight-bold">Notes: </span>{{ billing.serviceType ? billing.serviceType : '--' }}</p>
        </v-col>
        <v-col align="end" cols="3">
          <v-row justify="space-between" class="mb-5">
            <span class="font-weight-bold" style="text-align: end">Subtotal</span>
            <span>{{ formatToIDRCurrency(billing.amount) }}</span>
          </v-row>
          <v-row justify="space-between" class="mb-5">
            <span class="font-weight-bold" style="text-align: end">Total</span>
            <span>{{ formatToIDRCurrency(billing.amount) }}</span>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import { mdiChevronLeft, mdiDownload } from '@mdi/js'

export default {
  name: 'PDFLayout',
  components: {},
  setup() {
    return {
      icons: {
        mdiChevronLeft,
        mdiDownload,
      },
    }
  },
  props: ['billing', 'transaction', 'loanSub', 'project'],
  methods: {
    formatToIDRCurrency(number) {
      return number ? new Intl.NumberFormat('id-ID').format(number) : 0
    },
    formatDate(date) {
      return this.$moment(new Date(date)).format('DD MMM YYYY HH:mm')
    },
  },
}
</script>
